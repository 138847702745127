import React from "react"
import { Box } from "grommet"
import { graphql } from "gatsby"

import App from "../components/layout"
import Section from "../components/section"
import { renderAst } from "../tools"

function Live({ data }) {
  return (
    <App title="Jochen On Tour - Live">
      <Section>
        <Box
          margin={{ horizontal: `xlarge`, vertical: `small` }}
          pad="medium"
          align="center"
          elevation="medium"
        >
          {renderAst(data.markdownRemark.htmlAst)}
        </Box>
      </Section>
    </App>
  )
}

export const pageQuery = graphql`
  query LiveyQuery {
    markdownRemark(frontmatter: { path: { eq: "/live" } }) {
      htmlAst
      frontmatter {
        path
      }
      tableOfContents(pathToSlugField: "frontmatter.path")
    }
  }
`

export default Live
